.m-resultItem {
    border: 1px solid $anti-flash-white1;
    @include adjust-flex(flex-start, stretch);
    margin-bottom: rem(8);

    &:last-child {
        margin-bottom: rem(0);
    }

    &_label {
        padding: rem(8);
        flex: 1;
    }

    &_amount {
        padding: rem(8);
        background-color: $anti-flash-white1;
        flex: 0 0 calc(88 / 359 * 100%);
        max-width: rem(88);
        @include adjust-flex(center, center);
        flex-direction: column;
        flex-wrap: wrap;
        word-break: break-word;
    }
}
