@import '~slick-carousel/slick/slick-theme.css';
@import '~slick-carousel/slick/slick.css';

@import 'styles/common';

// Components
@import 'components/atoms/Button/index.scss';
@import 'components/atoms/Heading/index.scss';
@import 'components/atoms/Icon/index.scss';
@import 'components/atoms/Image/index.scss';
@import 'components/atoms/Input/index.scss';
@import 'components/atoms/Link/index.scss';
@import 'components/atoms/Loading/index.scss';
@import 'components/atoms/Text/index.scss';
@import 'components/atoms/Textarea/index.scss';
@import 'components/molecules/ProcessTitle/index.scss';
@import 'components/molecules/Pulldown/index.scss';
@import 'components/molecules/ResultItem/index.scss';
@import 'components/molecules/SectionTitle/index.scss';
@import 'components/molecules/Signature/index.scss';
@import 'components/organisms/Card/index.scss';
@import 'components/organisms/Carousel/index.scss';
@import 'components/organisms/Header/index.scss';
@import 'components/organisms/InfoCard/index.scss';
@import 'components/organisms/Modal/index.scss';
@import 'components/organisms/Table/index.scss';
@import 'components/templates/MainLayout/index.scss';
@import 'components/templates/NotifyModal/index.scss';
@import 'components/templates/PageLayout/index.scss';
@import 'components/templates/PopupTable/index.scss';
@import 'pages/Home/index.scss';
